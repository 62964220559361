import useLocalization from "@/hooks/useLocalization";
import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import LogoWithEnvironment from "../LogoWithEnvironment";

type LoginPageTemplateProps = {
  onClick?: () => void;
};
export default function PublicRouteTemplate({
  onClick,
  children,
}: React.PropsWithChildren<LoginPageTemplateProps>) {
  const { t } = useLocalization();
  const theme = useTheme();
  return (
    <Stack
      direction="column"
      alignItems="center"
      gap="16px"
      sx={{
        paddingLeft: "1rem",
        paddingRight: "1rem",
        position: "relative",
        top: "10%",
        maxWidth: "26rem",
        margin: "auto",
      }}
    >
      <Box
        sx={{ cursor: "pointer", textAlign: "center" }}
        onClick={onClick}
        data-testid="logo-box"
      >
        <LogoWithEnvironment
          height={128}
          color={theme.palette.primary.main}
          top={0}
          left={"50%"}
        />
        <Typography variant="h5" color="primary" style={{ marginBottom: 32 }}>
          {t("nouns:siteName")}
        </Typography>
      </Box>
      {children}
    </Stack>
  );
}
