import useLocalization from "@/hooks/useLocalization";
import useReservation from "@/hooks/useReservation";
import useVenue from "@/hooks/useVenue";
import icons from "@/tools/icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { format, isEqual } from "date-fns";
import { useState } from "react";
import SearchMenu from "../../../components/SearchMenu/SearchMenu";

export default function SearchBar() {
  const [expanded, setExpanded] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { from, until, venueId, productId, resetSearch, hasSearchParams } =
    useReservation();
  const { t } = useLocalization();
  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev); // line 24
  };

  const { venueMap, productMap } = useVenue();

  const venue = venueMap.get(venueId || "");
  const product = productMap.get(productId || "");

  return (
    <Accordion
      disableGutters
      expanded={expanded}
      defaultExpanded={false}
      onChange={(_, x) => setExpanded(x)}
      sx={{
        "& .MuiAccordionSummary-content": {
          marginTop: "8px",
          marginBottom: "8px",
        },
      }}
    >
      <AccordionSummary data-testid="search-accordian">
        <Stack direction="row" sx={{ width: "100%" }} gap={2}>
          <Stack
            direction="column"
            justifyContent="center"
            sx={{ flexGrow: 1 }}
            gap={1}
          >
            {venue && <RowWithIcon body={venue.name} Icon={icons.Venue} />}
            {product && <RowWithIcon body={product.name} Icon={icons.Tour} />}
            {from && (
              <RowWithIcon
                body={formatDateRange(from, until)}
                Icon={icons.Calendar}
              />
            )}
          </Stack>
          <Stack direction="row" alignItems="flex-start" gap={1}>
            {hasSearchParams && (
              <IconButton
                onClick={(event) => {
                  event.stopPropagation(); // line 66
                  resetSearch();
                }}
              >
                <icons.Close />
              </IconButton>
            )}
            <IconButton
              data-testid="search-button"
              onClick={toggleMenu}
              aria-label={
                isMenuOpen
                  ? t("command:closeWhat", {
                      interpolation: { what: t("nouns:searchMenu") },
                    })
                  : t("command:openWhat", {
                      interpolation: { what: t("nouns:searchMenu") },
                    })
              }
            >
              <icons.Search />
            </IconButton>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <SearchMenu
          onSearch={async () => {
            setExpanded(false); // line 94
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}

const formatDateRange = (
  from: Date,
  until: Date | null // line 102
) =>
  `${format(from, "yyyy-MM-dd")}${
    until && !isEqual(from, until) ? ` - ${format(until, "yyyy-MM-dd")}` : ""
  }`;

type RowWithIconProps = {
  body: string;
  Icon: (typeof icons)[keyof typeof icons];
};
function RowWithIcon({ body, Icon }: RowWithIconProps) {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Icon />
      <Typography>{body}</Typography>
    </Stack>
  );
}
