import { VenueReservation } from "@/graphql/operations";
import useReservation from "@/hooks/useReservation";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useCallback } from "react";
import { Virtuoso } from "react-virtuoso";
import ReservationRow from "../ReservationRow/ReservationRow";
import Footer from "./Footer";

type VirtualReservationTableProps = {
  reservations: VenueReservation[];
  selectedReservation: VenueReservation | null;
  selectReservation: (reservationId: string) => void;
};

export default function VirtualReservationTable({
  reservations,
  selectReservation,
  selectedReservation,
}: VirtualReservationTableProps) {
  const {
    canFetchMoreReservations,
    fetchMoreReservations,
    loading,
    totalReservations,
  } = useReservation();

  const onEndReached = useCallback(() => {
    if (!loading && canFetchMoreReservations) fetchMoreReservations();
  }, [loading, canFetchMoreReservations, fetchMoreReservations]);

  return (
    <Virtuoso
      data={reservations}
      totalCount={totalReservations}
      endReached={onEndReached}
      increaseViewportBy={200}
      itemContent={(_, reservation) => (
        <Box key={reservation.id} data-testid="virtuoso-item-list">
          <ReservationRow
            reservation={reservation}
            isSelected={selectedReservation?.id === reservation.id}
            selectReservation={() => selectReservation(reservation.id)}
          />
          <Divider />
        </Box>
      )}
      components={{
        Footer: () => (
          <Footer
            canFetchMoreReservations={canFetchMoreReservations}
            totalReservations={totalReservations}
            loading={loading}
          />
        ),
      }}
      data-testid="virtual-reservation-table"
    />
  );
}
