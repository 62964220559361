import { VenueReservation } from "@/graphql/operations";
import useLocalization from "@/hooks/useLocalization";
import useReservation, { reservationHasPassed } from "@/hooks/useReservation";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ResetReservationButton from "./ResetReservationButton";

type EditReservationStatusButtonsProps = {
  reservation: VenueReservation;
  disabled?: boolean;
};
export default function EditReservationStatusButtons({
  reservation,
  disabled,
}: EditReservationStatusButtonsProps) {
  const { acceptReservation, rejectReservation } = useReservation();

  const { t } = useLocalization();

  return (
    <Box sx={{ flex: 1 }} data-testid="edit-reservation-status-button">
      {reservation?.answer || reservationHasPassed(reservation) ? (
        <ResetReservationButton reservation={reservation} disabled={disabled} />
      ) : (
        <Stack direction="row" spacing={1}>
          <Button
            color="success"
            variant="contained"
            fullWidth
            onClick={() => acceptReservation(reservation.id)}
            disabled={disabled}
            aria-label={t("command:acceptWhat", {
              interpolation: { what: t("nouns:reservation") },
            })}
          >
            {t("command:accept")}
          </Button>
          <Button
            color="error"
            variant="contained"
            fullWidth
            onClick={() => rejectReservation(reservation.id)}
            disabled={disabled}
            aria-label={t("command:rejectWhat", {
              interpolation: { what: t("nouns:reservation") },
            })}
          >
            {t("command:reject")}
          </Button>
        </Stack>
      )}
    </Box>
  );
}
