import { convertLocalToUtc } from "@/tools/date/conversion";
import { Dispatch, SetStateAction, useCallback } from "react";
import { DateRangePickerProps } from "./DateRangePicker";
import { Day } from "./Day";
import WideStaticDatePicker from "./WideStaticDatePicker";
import { FromOrUntilFocus } from "./types";

type DateRangeCalendarProps = DateRangePickerProps & {
  closeCalendar: () => void;
  focus: FromOrUntilFocus;
  setFocus: Dispatch<SetStateAction<FromOrUntilFocus>>;
  width: number;
};

export function DateRangeCalendar({
  closeCalendar,
  setFrom,
  from,
  until,
  setUntil,
  focus,
  setFocus,
  width,
}: DateRangeCalendarProps) {
  const onDaySelect = useCallback(
    (value: Date) => {
      const utcValue = convertLocalToUtc(value);
      if (focus === "from") {
        setFrom(utcValue);
        if (until && utcValue && until < utcValue) setUntil(null);
        setFocus("until");
      } else if (focus === "until") {
        if (from && utcValue && utcValue < from) {
          setFrom(utcValue);
        } else {
          setUntil(utcValue);
          setFocus(null);
        }
      }
    },
    [from, until, focus, setFrom, setUntil, setFocus]
  );

  return (
    <div data-testid="date-range-calendar">
      <WideStaticDatePicker
        showDaysOutsideCurrentMonth={false}
        sx={{ width }}
        value={from}
        slotProps={{
          actionBar: {
            actions: ["clear", "accept"],
            onClear: () => {
              setFrom(null);
              setUntil(null);
              setFocus(null);
              closeCalendar();
            },
            onAccept: () => {
              setFocus(null);
            },
          },
          day: (props) => ({
            onDaySelect,
            from,
            until,
            ...props,
          }),
        }}
        slots={{
          // @ts-expect-error day is missing props
          day: Day,
          toolbar: undefined,
        }}
      />
    </div>
  );
}
