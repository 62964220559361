import EditPasswordForm from "@/components/EditPasswordForm/EditPasswordForm";
import { validatePassword } from "@/components/EditPasswordForm/validatePassword";
import PublicRouteTemplate from "@/components/PublicRouteTemplate/PublicRouteTemplate";
import useLocalization from "@/hooks/useLocalization";
import useUser from "@/hooks/useUser";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function ResetPasswordPage() {
  const [password, setPassword] = useState(""); // line 12
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const { t, setLocalization } = useLocalization();

  const navigate = useNavigate();

  const errors = useMemo(
    () => validatePassword(password, confirmationPassword),
    [password, confirmationPassword]
  );

  const { getRequestPasswordEmail } = useUser();
  const id = useParams().id!;

  const loading = false;
  const [email, setEmail] = useState<string | undefined | null>(undefined);
  const [errorType, setErrorType] = useState<
    "Expired" | "Used" | "Failed" | null
  >(null);

  useEffect(
    () => {
      getRequestPasswordEmail({ id }).then(
        ({ isUsed, isExpired, email, iso639_1, error }) => {
          setEmail((!(isExpired || isUsed) && email) || null);
          setLocalization(iso639_1 ?? "en");
          setErrorType(
            ([
              { condition: !!error, errorType: "Failed" },
              { condition: isUsed, errorType: "Used" },
              { condition: isExpired, errorType: "Expired" },
            ].find(({ condition }) => condition)?.errorType ??
              null) as typeof errorType
          );
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <PublicRouteTemplate onClick={() => navigate("/login")}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography>
            {email === null ? t(`error:passwordRequest${errorType!}`) : email}
          </Typography>
          {email && (
            <EditPasswordForm
              errors={errors}
              password={password}
              setPassword={setPassword}
              confirmationPassword={confirmationPassword}
              setConfirmationPassword={setConfirmationPassword}
              requestId={id}
              onCompleted={() => navigate("/login")} // line 70
            />
          )}
        </>
      )}
    </PublicRouteTemplate>
  );
}
