import LoadingButton from "@/components/LoadingButton/LoadingButton";
import { VenueReservation } from "@/graphql/operations";
import useLocalization from "@/hooks/useLocalization";
import useMailer from "@/hooks/useMailer";
import { AccordionSummary } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Dispatch, SetStateAction, useState } from "react";

type ReservationInquiryProps = Pick<
  VenueReservation,
  "id" | "latestInquiry"
> & {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export default function ReservationInquiry({
  latestInquiry,
  id,
  isOpen,
  setIsOpen,
}: ReservationInquiryProps) {
  const { t } = useLocalization();
  const [body, setBody] = useState(latestInquiry?.body ?? "");
  const {
    sendReservationInquiryEmail,
    sendReservationInquiryEmailResult: { loading },
  } = useMailer();

  return (
    <Accordion
      sx={{
        "::before": {
          display: "none",
        },
        "& .MuiAccordionSummary-root": {
          display: "none",
        },
        "& .MuiAccordionDetails-root": {
          padding: "8px 0 0 0",
        },
      }}
      disableGutters
      expanded={isOpen}
      onChange={() => setIsOpen((isOpen) => !isOpen)}
    >
      <AccordionSummary />
      <AccordionDetails>
        <Stack gap={1}>
          <TextField
            disabled={loading}
            label="inquiry"
            focused
            placeholder={t("messages:inquiryExplanation")}
            multiline
            minRows={8}
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
          <LoadingButton
            data-testid="loading-button"
            disabled={!body || loading}
            variant="contained"
            onClick={async () => {
              await sendReservationInquiryEmail(id, body, {
                onCompleted: () => {
                  setIsOpen(false);
                },
              });
            }}
          >
            {t(`command:${loading ? "sending" : "send"}What`, {
              interpolation: {
                what: t("nouns:inquiry"),
              },
            })}
          </LoadingButton>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
