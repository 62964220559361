import useLocalization from "@/hooks/useLocalization";
import icons from "@/tools/icons";
import Button from "@mui/material/Button";
import { Dispatch, SetStateAction } from "react";

type ToggleInquiryButtonProps = {
  setOpenInquiry: Dispatch<SetStateAction<boolean>>;
};
export default function ToggleInquiryButton({
  setOpenInquiry,
}: ToggleInquiryButtonProps) {
  const { t } = useLocalization();
  return (
    <Button
      variant="contained"
      data-testid="inquiry-button"
      onClick={() => setOpenInquiry((openInquiry) => !openInquiry)}
      aria-label={t("command:toggleWhat", {
        interpolation: {
          what: t("nouns:inquiry"),
        },
      })}
    >
      {<icons.Email />}
    </Button>
  );
}
