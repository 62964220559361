import { visualRoutes } from "@/Router/routes";
import VenuePortalUserOptionsAutocomplete from "@/components/VenuePortalUserOptionsAutocomplete/VenuePortalUserOptionsAutocomplete";
import useLocalization from "@/hooks/useLocalization";
import useUser from "@/hooks/useUser";
import icons from "@/tools/icons";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import LogoWithEnvironment from "../../LogoWithEnvironment";

export default function SideBar() {
  const navigate = useNavigate();
  const { logout, isMtUser } = useUser();
  const { t } = useLocalization();

  const theme = useTheme();

  const currentPath = useLocation().pathname;

  return (
    <Box sx={{ position: "sticky", top: 0, left: 0, minWidth: "200px" }}>
      <List
        disablePadding
        sx={{
          backgroundColor: "primary.dark",
          height: "100vh",
          color: theme.custom.sideBarTextColor,
        }}
      >
        <ListItem sx={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          <LogoWithEnvironment />
        </ListItem>

        {visualRoutes.map(({ path, visual: { id, icon, ariaLabel } }) => (
          <ListItem key={id} disablePadding>
            <ListItemButton
              selected={currentPath === path}
              onClick={() => {
                navigate(path);
              }}
            >
              <ListItemIcon sx={{ color: theme.custom.sideBarTextColor }}>
                <span aria-label={ariaLabel}>{icon}</span>
              </ListItemIcon>

              <ListItemText
                sx={{ textWrap: "nowrap" }}
                primary={t(`page:${id}`)}
              />
            </ListItemButton>
          </ListItem>
        ))}

        <Box sx={{ position: "absolute", width: "100%", bottom: 0 }}>
          {isMtUser && (
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <icons.Fingerprint />
                </ListItemIcon>
                <VenuePortalUserOptionsAutocomplete
                  size="small"
                  fullWidth
                  disableClearable
                  forcePopupIcon={false}
                  sx={{ maxWidth: "100px" }}
                  slotProps={{
                    popper: {
                      sx: { width: "auto !important" },
                    },
                  }}
                  textFieldProps={{
                    variant: "standard",
                    sx: { maxWidth: "100px" },
                  }}
                />
              </ListItemButton>
            </ListItem>
          )}

          <ListItem disablePadding>
            <ListItemButton onClick={logout}>
              <ListItemIcon>
                <span aria-label="logout icon">
                  <icons.Logout />
                </span>
              </ListItemIcon>
              <ListItemText primary={t("command:logOut")} />
            </ListItemButton>
          </ListItem>
        </Box>
      </List>
    </Box>
  );
}
