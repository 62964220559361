import CircularTimerButton, {
  CircularTimerButtonProps,
} from "@/components/TimerButton/CircularTimerButton/CircularTimerButton";
import { VenueReservation } from "@/graphql/operations";
import useReservation, {
  getReservationStatus,
  reservationHasPassed,
  reservationStatus,
} from "@/hooks/useReservation";
import icons, { Icon } from "@/tools/icons";
import Badge from "@mui/material/Badge";
import { SvgIconOwnProps } from "@mui/material/SvgIcon";
import { resetReservationGraceTimeMilliseconds } from "@shared/constants";
import { useMemo } from "react";

type StatusIconProps = Pick<CircularTimerButtonProps, "sx"> & {
  reservation: VenueReservation;
};
export default function StatusIcon({ reservation, sx }: StatusIconProps) {
  const { resetReservation } = useReservation();
  const disabled = useMemo(
    () =>
      reservationHasPassed(reservation) ||
      reservation.answeredBy === "MagicalTrip",
    [reservation]
  );

  const {
    color,
    Icon,
    onClick,
  }: {
    color: SvgIconOwnProps["color"];
    onClick?: (reservationId: VenueReservation["id"]) => void;
    Icon: Icon;
  } = useMemo(
    () =>
      ({
        [reservationStatus.accepted]: {
          Icon: icons.Accept,
          color: "success" as SvgIconOwnProps["color"],
          onClick: resetReservation,
        },
        [reservationStatus.rejected]: {
          Icon: icons.Reject,
          color: "error" as SvgIconOwnProps["color"],
          onClick: resetReservation,
        },
        [reservationStatus.cancelled]: {
          Icon: icons.Cancel,
          color: "disabled" as SvgIconOwnProps["color"],
        },
        [reservationStatus.pending]: {
          Icon: icons.Unknown,
          color: (disabled ? "error" : "secondary") as SvgIconOwnProps["color"],
        },
      }[getReservationStatus(reservation)]),
    [reservation, disabled, resetReservation]
  );

  const isPendingReservationUpdate = useMemo(
    () =>
      !(
        disabled ||
        reservation.answer ||
        !reservation.originalReservation?.answer
      ),
    [disabled, reservation.answer, reservation.originalReservation?.answer]
  );

  return (
    <CircularTimerButton
      sx={sx}
      color={color}
      disabled={disabled}
      pause={!reservation.answer}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.(reservation.id);
      }}
      size={41}
      thickness={6}
      start={
        reservation.answer
          ? Number(reservation.reservationAnswerReceived)
          : undefined
      }
      duration={resetReservationGraceTimeMilliseconds / 2}
      disableTimer={disabled}
    >
      {
        <Badge badgeContent={isPendingReservationUpdate && <icons.Change />}>
          <Icon fontSize="large" color={color} data-testid="status-icon" />
        </Badge>
      }
    </CircularTimerButton>
  );
}
