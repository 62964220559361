import useLocalization from "@/hooks/useLocalization";
import { TextField } from "@mui/material";
import { format } from "date-fns";
import { Dispatch, SetStateAction, useMemo } from "react";
import { FromOrUntil, FromOrUntilFocus } from "./types";

type DateRangeTextFieldProps = {
  date: Date | null;
  focus: FromOrUntilFocus;
  setFocus: Dispatch<SetStateAction<FromOrUntilFocus>>;
  fromOrUntil: FromOrUntil;
};

export function DateRangeTextField({
  date,
  focus,
  setFocus,
  fromOrUntil,
}: DateRangeTextFieldProps) {
  const { t } = useLocalization();

  const formattedDate = useMemo(
    () => (date ? format(date, "yyyy-MM-dd") : ""),
    [date]
  );
  return (
    <TextField
      data-testid={`${fromOrUntil}-text-field`}
      value={formattedDate}
      label={t(`nouns:${fromOrUntil}`)}
      onClick={() =>
        setFocus((focus) => (focus === fromOrUntil ? null : fromOrUntil))
      }
      focused={focus === fromOrUntil}
      autoComplete="off"
    />
  );
}
