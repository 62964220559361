import { getColorFromTheme } from "@/tools/theme/getColor";
import { SxProps, Theme, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

type ExpandableAccordionProps = {
  summary: JSX.Element;
  details: JSX.Element;
  isExpanded: boolean;
  onClick: () => void;
  sx?: SxProps<Theme>;
};
export default function ExpandableAccordion({
  summary,
  details,
  isExpanded,
  onClick,
  sx,
}: ExpandableAccordionProps) {
  const theme = useTheme();

  return (
    <Accordion
      disableGutters
      expanded={isExpanded}
      onChange={onClick}
      slotProps={{ transition: { unmountOnExit: true } }}
      sx={sx}
      data-testid="mobile-reservation-row-accordion"
    >
      <AccordionSummary
        sx={{
          ...(isExpanded && {
            backgroundColor: getColorFromTheme(theme, "primary", "main", 0.16),
          }),
        }}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: "background.paper" }}>
        {details}
      </AccordionDetails>
    </Accordion>
  );
}
